/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {get} from '../resources/core/_requests'
import {useAuth} from '../auth'

const DashboardPage: FC = () => <></>

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [orderCount, setOrderCount] = useState<number>(0)
  const [branchCount, setBranchCount] = useState<number>(0)
  const [showroomCount, setShowroomCount] = useState<number>(0)
  const [debiturCount, setDebiturCount] = useState<number>(0)
  const [surveyCount, setSurveyCount] = useState<number>(0)
  const [masterCarCount, setMasterCarCount] = useState<number>(0)

  const fetchCountOrder = async () => {
    try {
      const response = await get('orders')
      const entries = response.data.count
      setOrderCount(entries)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCountBranch = async () => {
    try {
      const response = await get('branch')
      const entries = response.data.count
      setBranchCount(entries)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCountShowroom = async () => {
    try {
      const response = await get('showroom')
      const entries = response.data.count
      setShowroomCount(entries)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCountDebitur = async () => {
    try {
      const response = await get('debitur')
      const entries = response.data.count
      setDebiturCount(entries)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCountSurvey = async () => {
    try {
      const response = await get('survey')
      const entries = response.data.count
      setSurveyCount(entries)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCountMasterCar = async () => {
    try {
      const response = await get('assets_combine')
      const entries = response.data.count
      setMasterCarCount(entries)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchCountOrder()
    fetchCountBranch()
    fetchCountShowroom()
    fetchCountDebitur()
    fetchCountSurvey()
    fetchCountMasterCar()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
      <div className='card'>
        {/* <div className="card-header border-0 pt-6 d-flex align-items-center">
          <h1>Dashboard</h1>
        </div> */}
        <div className='card-body py-4'>
          {/* content */}
          <div className='d-flex gap-6 align-items-center justify-content-center mb-6'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/icons/dashboard/welcome.png')}
              height={200}
            />
            <div>
              <div className='h2 fw-normal'>Welcome Back</div>
              <div className='display-6 text-blue'>
                {currentUser?.first_name || ''} {currentUser?.last_name || ''}
              </div>
            </div>
          </div>
          <div className='row g-6 dashboard'>
            <div className='col-4'>
              <div className='d-flex justify-content-between align-items-center p-4 dashboard-item'>
                <div className='d-flex gap-4 align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/dashboard/car.png')}
                    height={48}
                  />
                  <h2 className='text-gray-700'>Master Car</h2>
                </div>
                <h3 className='display-5'>{masterCarCount}</h3>
              </div>
            </div>
            <div className='col-4'>
              <div className='d-flex justify-content-between align-items-center p-4 dashboard-item'>
                <div className='d-flex gap-4 align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/dashboard/order.png')}
                    height={48}
                  />
                  <h2 className='text-gray-700'>Order</h2>
                </div>
                <h3 className='display-5'>{orderCount}</h3>
              </div>
            </div>
            {/* <div className='col-4'>
              <div className='d-flex justify-content-between align-items-center p-4 dashboard-item'>
                <div className='d-flex gap-4 align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/dashboard/debitur.png')}
                    height={48}
                  />
                  <h2 className='text-gray-700'>Debitur</h2>
                </div>
                <h3 className='display-5'>{debiturCount}</h3>
              </div>
            </div> */}
            <div className='col-4'>
              <div className='d-flex justify-content-between align-items-center p-4 dashboard-item'>
                <div className='d-flex gap-4 align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/dashboard/branch.png')}
                    height={48}
                  />
                  <h2 className='text-gray-700'>Branch</h2>
                </div>
                <h3 className='display-5'>{branchCount}</h3>
              </div>
            </div>
            <div className='col-4'>
              <div className='d-flex justify-content-between align-items-center p-4 dashboard-item'>
                <div className='d-flex gap-4 align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/dashboard/showroom.png')}
                    height={48}
                  />
                  <h2 className='text-gray-700'>Showroom</h2>
                </div>
                <h3 className='display-5'>{showroomCount}</h3>
              </div>
            </div>
            <div className='col-4'>
              <div className='d-flex justify-content-between align-items-center p-4 dashboard-item'>
                <div className='d-flex gap-4 align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/dashboard/survey.png')}
                    height={48}
                  />
                  <h2 className='text-gray-700'>Master Survey</h2>
                </div>
                <h3 className='display-5'>{surveyCount}</h3>
              </div>
            </div>
          </div>
          {/* end content */}
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
