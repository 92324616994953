import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../_metronic/assets/ts/_utils'
import {WithChildren} from '../_metronic/helpers'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const UserPage = lazy(() => import('../pages/user/router/IndexRoutes'))
  const SysparamPage = lazy(() => import('../pages/sysparam/router/IndexRoutes'))
  const RolePage = lazy(() => import('../pages/role/router/IndexRoutes'))
  const PrivilegePage = lazy(() => import('../pages/privileges/router/IndexRoutes'))
  const BranchPage = lazy(() => import('../pages/branch/router/IndexRoutes'))
  const ShowroomPage = lazy(() => import('../pages/showroom/router/IndexRoutes'))
  const DebiturPage = lazy(() => import('../pages/debitur/router/IndexRoutes'))
  const OrderPage = lazy(() => import('../pages/orders/router/IndexRoutes'))
  const MasterCarPage = lazy(() => import('../pages/master_car/router/IndexRoutes'))
  const MasterSurveyPage = lazy(() => import('../pages/master_survey/router/IndexRoutes'))
  const SupplierPage = lazy(() => import('../pages/supplier/router/IndexRoutes'))
  const AssetsCombinePage = lazy(() => import('../pages/assets_combine/router/IndexRoutes'))
  const BannerPage = lazy(() => import('../pages/banner/router/IndexRoutes'))
  const ReportPage = lazy(() => import('../pages/report_order/router/IndexRoutes'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='user/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='sysparam/*'
          element={
            <SuspensedView>
              <SysparamPage />
            </SuspensedView>
          }
        />
        <Route
          path='branch/*'
          element={
            <SuspensedView>
              <BranchPage />
            </SuspensedView>
          }
        />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='master_car/*'
          element={
            <SuspensedView>
              <MasterCarPage />
            </SuspensedView>
          }
        />
        <Route
          path='showroom/*'
          element={
            <SuspensedView>
              <ShowroomPage />
            </SuspensedView>
          }
        />
        <Route
          path='debitur/*'
          element={
            <SuspensedView>
              <DebiturPage />
            </SuspensedView>
          }
        />

        <Route
          path='master_survey/*'
          element={
            <SuspensedView>
              <MasterSurveyPage />
            </SuspensedView>
          }
        />

      <Route
          path='report_order/*'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />

        <Route
          path='role/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />

        <Route
          path='privileges/*'
          element={
            <SuspensedView>
              <PrivilegePage />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/*'
          element={
            <SuspensedView>
              <SupplierPage />
            </SuspensedView>
          }
        />
        <Route
          path='assets_combine/*'
          element={
            <SuspensedView>
              <AssetsCombinePage />
            </SuspensedView>
          }
        />
        <Route
          path='banner/*'
          element={
            <SuspensedView>
              <BannerPage />
            </SuspensedView>
          }
        />

        {/* <Route path=':collection' element={<ResourceWrapper />}>
          <Route index element={<ListWrapper />} />
          <Route path='create' element={<CreateWrapper />} />
          <Route path=':id'>
            <Route index element={<ReadWrapper />} />
            <Route path='edit' element={<EditWrapper />} />
          </Route>
        </Route> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
